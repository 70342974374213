(function(mainFunc) {

  	mainFunc(window.jQuery, window, document);

 }
	(function($, window, document) {

		//DOCUMENT READY
		$(function() {
			
			$('#homeCarousel').on('slid.bs.carousel', function(){
				homeCarousel();
			});

			$('#mediaplayer').mediaelementplayer({
				features: [],
				alwaysShowControls: false,
				AndroidUseNativeControls: false,
				iPhoneUseNativeControls: false,
				iPadUseNativeControls: false,
				alwaysShowHours: false
			});

			//ROOMS
			$('.btn.check-availability').click(function(){
				$(this)
					.closest('.room-inner-container')
					.find('.availability')
						.fadeIn()
					.end()
					.find('.details')
						.hide();
			});

			$('.btn.back').click(function(){
				$(this)
					.closest('.room-inner-container')
						.find('.details')
							.fadeIn()
						.end()
						.find('.availability')
							.hide();
			});

			//GALLERY FANCYBOX
			$('.fancybox').fancybox();
			$('.fancybox-buttons').fancybox({
				openEffect  : 'fade',
				closeEffect : 'fade',
				showNavArrows : true,
				helpers : {
					title : { type : 'inside' },
				}
			});

			$('.view-album').click(function(){
				$(this)
					.closest('.gallery-item')
						.find('> .fancybox')
							.click();
			});

			$('.date-field').datepicker();

		});

		$(window).on('resize', function(){
			//HOME SLIDER
			homeCarousel();

			//STICKY HEADER
			if( Modernizr.mq('(max-width: 1199px)') ) {
				var $headerHeight = $('.main-header').outerHeight();
				$('.page-banner').css({
					marginTop: $headerHeight
				})
			}
			else {
				$('.page-banner').css({
					marginTop: 0
				});
			}

			//FOOTER
			var $footer = $('.footer').outerHeight();
			$('.wrapper-holder').css({
				paddingBottom: $footer
			})

			//EQUALIZE
			$('.rates-wrap .section-container').equalize({
				windowStart: 768,
				coverage: 'min-width'
			});

			$('.fg-wrapper').equalize({
				windowStart: 768,
				coverage: 'min-width'
			});

			//INNER PAGE CONTENT
			if( Modernizr.mq('(min-width: 768px)') ) {
				var $maxHeight = 0;
				
				$('.innerpage-wrapper').each(function(){

					if( $(this).outerHeight() > $maxHeight ) {
						$maxHeight = $(this).outerHeight();
					}

				}).css({
					minHeight: $maxHeight
				});


				$('.sa-single').each(function(){

					if( $(this).outerHeight() > $maxHeight ) {
						$maxHeight = $(this).outerHeight();
					}

				}).css({
					minHeight: $maxHeight
				});

			}
			else {
				$('.innerpage-wrapper').css({
					minHeight: 0
				});

				$('.sa-single').css({
					minHeight: 0
				});
			}


		});

		$(window).load(function(){

			$(window).resize();

		});

		$(window).on('scroll', function(){

			if( Modernizr.mq('(min-width: 1200px)') ) {

				var $scrollTop = $(window).scrollTop();

				if( $scrollTop > 300 ) {
					$('.main-header').addClass('scrolled');
				}
				else {
					$('.main-header').removeClass('scrolled');
				}

			}

		});

		//FUNCTIONS
		function homeCarousel() {
			if( Modernizr.mq('(min-width:540px)') ) {
				var $captionHeight = $('#homeCarousel').find('.active').find('.caption').outerHeight();

				$('#homeCarousel').find('.item.active').find('.caption').css({
					marginTop: -(parseInt($captionHeight) / 2)
				});
			}
			else {				
				$('#homeCarousel').find('.item.active').find('.caption').css({
					marginTop: 0
				});
			}
		}
		
	})
);
